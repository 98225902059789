import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "../Pages/styles.css";

import Login from "../Pages/Login.js";
import Register from "../Pages/Register.js";
import Dashboard from "../Pages/Dashboard.js";
import Topup from "../Pages/Topup.js";
import CallerID from "../Pages/CallerID.js";
import Pricing from "../Pages/Pricing.js";
import PrivateRoute from "./CMPRouteGuard";
import AuthProvider from './CMPAuthContext.js';
import Subscription from '../Pages/Subscription.js';


const CMPHeader = () => {

  return (
    <>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/"element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/topup" element={<Topup />} />
              <Route path="/callerid" element={<CallerID />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/subscription" element={<Subscription />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </>
  );
};

export default CMPHeader;
