import React, { Component } from 'react'
import { useState } from "react";
import { Container, Button, Form, Alert } from "react-bootstrap";
import { useAuth } from "../Components/CMPAuthContext";
import "./styles.css";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [linked_user, setLinkedUser] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePassword2Change = (event) => {
    setPassword2(event.target.value);
    if(password2 !== password) {
      //setError("Please make sure your passwords equal");
      //Add red to box?!
    } else {
      //setError(false);
    }
  };

  const handleLinkedUserChange = (event) => {
    setLinkedUser(event.target.value);
  };

  const auth = useAuth();
  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    if (username !== "" && password !== "" && linked_user !== "") {
      if(password === password2) {
        const result = await auth.registerAction({
          username: username,
          password: password,
          password2: password2,
          linked_user: linked_user,
        });

        if(result === true) {
          setSuccess("Successfully registered, you can now login!");
        } else {
          setSuccess(false);
          setError(result);
        }
        return;
      } else {
        setError("Please make sure your passwords equal");
      }
    } else {
      setError("Please provide a valid input");
    }
  };

  return (
    <>
      <Container className="d-flex align-items-center justify-content-center flex-column pt-5 gap-3">
          <h1>Register a new account</h1>
          <Form onSubmit={handleSubmitEvent}>
            {error && (
              <Alert variant="danger">
                {error}
              </Alert>
            )}

          {success && (
              <Alert variant="success">
                {success}
              </Alert>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={username}
                placeholder="Enter desired Username"
                onChange={handleUsernameChange}
                id="username"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Linked SIP User</Form.Label>
              <Form.Control
                type="text"
                value={linked_user}
                placeholder="Enter Linked SIP Username"
                onChange={handleLinkedUserChange}
                id="username"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Password"
                id="Password"
              />
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label>Confirm password</Form.Label>
              <Form.Control
                type="password"
                value={password2}
                onChange={handlePassword2Change}
                placeholder="Confirm your password"
                id="PasswordConfirm"
              />
            </Form.Group>


            <div className="d-flex gap-3">
            <Button className="btn-trans" type="submit" id="registerSubmit">
              Register
            </Button>
            <Button className="btn-trans" type="submit" href="/" id="registerLogin">
              Already have an account?
            </Button>
            </div>
          </Form>
        </Container>
      </>
  );
};

export default Register;

/*export default class Register extends Component {
  render() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [linked_user, setLinkedUser] = useState("");
    const [error, setError] = useState("");

    const handleUsernameChange = (event) => {
      setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };

    const handleLinkedUserChange = (event) => {
      setLinkedUser(event.target.value);
    };

    const auth = useAuth();
    const handleSubmitEvent = (e) => {
      e.preventDefault();
      if (username !== "" && password !== "") {
        setError(auth.loginAction({
          username: username,
          password: password,
        }));
        return;
      }
      alert("pleae provide a valid input");
    };

    return (
      <>
      <Container className="d-flex align-items-center justify-content-center flex-column pt-5 gap-3">
          <h1>Register a new account</h1>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter desired email"
                id="Email"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                id="Password"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPasswordConfirm">
            <Form.Label>Confirm password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm your password"
                id="PasswordConfirm"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Remember me"
                id="Remember"
              />
            </Form.Group>
            <div className="d-flex gap-3">
            <Button className="btn-trans" type="submit" href="/panel" id="registerSubmit">
              Register
            </Button>
            <Button className="btn-trans" type="submit" href="/" id="registerLogin">
              Already have an account?
            </Button>
            </div>
          </Form>
        </Container>
      </>
    )
  }
}*/
