import React, { Component } from "react";
import DashboardUI from "../Components/CMPDashboard";
import PanelNavigation from "../Components/CMPPanelNav";

export default class Dashboard extends Component {
  state = {
    currentPage: "/panel",
  };

  setCurrentPage = (page) => {
    this.setState({ currentPage: page });
  };
  render() {
    return (
      <>
        <DashboardUI setCurrentPage={this.setCurrentPage} />
        <PanelNavigation setCurrentPage={this.setCurrentPage} />
      </>
    );
  }
}
