import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Navbar,
  Button
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { useAuth } from "../Components/CMPAuthContext";
import { Link } from 'react-router-dom';

import '../Pages/styles.css';

const CMPDashboard = () => {
  const [balance, setBalance] = useState("");
  const [hasRun, setHasRun] = useState(false);

  const [isDTMFCheckboxDisabled, setisDTMFCheckboxDisabled] = useState(false);
  const [dtmf, setDtmf] = useState(false);
  const [sendDTMFUpdate, setSendDTMFUpdate] = useState(false);

  const auth = useAuth();
  const getBalanceEvent = async () => {
    const result = await auth.getBalanceAction();

    if(!result) {
      auth.logOut();
    } else {
      setBalance(result);
    }
  };

  const setDTMFEvent = async () => {
    //setDtmf(!dtmf);
    setisDTMFCheckboxDisabled(true);

    const result = await auth.setDTMFAction({
      dtmf: dtmf,
    });

    setDtmf(result);
    setisDTMFCheckboxDisabled(false);
  };

  const handleDTMFChange = async (e) => {
    setSendDTMFUpdate(true);
    setDtmf(!dtmf);
  };

  const getDTMFEvent = async () => {
    setisDTMFCheckboxDisabled(true);
    const result = await auth.getDTMFAction();

    //TODO: Error Handling
    /*if(!result) {
      auth.logOut();
    } else {*/
      setisDTMFCheckboxDisabled(false);
      setDtmf(result);
    //}
  };
  
  useEffect(() => {
    if(sendDTMFUpdate) {
      setDTMFEvent();
      setSendDTMFUpdate(false);
    }

    if(!hasRun) {
      getDTMFEvent();
      getBalanceEvent();
      setHasRun(true);
    }
  });

  return (
    <>
      <div className="dashboard">
        <Navbar bg="dark" variant="dark" className="mb-3">
          <Container>
            <Navbar.Brand className="dashboard-title">Dashboard</Navbar.Brand>
            <Navbar.Toggle aria-controls="dashboard-navbar" />
            <Navbar.Collapse id="dashboard-navbar">
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Container>
          <Row className="dashboard-row">
            <h2>Welcome {auth.user},</h2>
            <hr />
            <Row xs={1} md={3} className="g-3">
              <Col lg={6}>
                <Card className="h-100 d-flex flex-column">
                  <Card.Body className="d-flex flex-column justify-content-center">
                    <h5>Balance</h5>
                    <p className="dashboard-balance" id="dashboard-balance">{balance} €</p>
                    <Link to="/topup"><Button variant="primary" id="dashboard-deposit">Deposit</Button></Link>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={6}>
                <Card className="h-100 d-flex flex-column">
                  <Card.Body className="d-flex flex-column justify-content-center">
                    <h5>Linked SIP</h5>
                    <p className="dashboard-linkedsip" id="dashboard-linkedsip">{auth.linked_sip}</p>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={6}>
                <Card className="h-100 d-flex flex-column">
                  <Card.Body className="d-flex flex-column justify-content-center">
                    <h5>Enable DTMF</h5>
                    <p className="dashboard-dtmfcheck" id="dashboard-dtmfcheck">
                      <label class="form-check-label form-check-toggle">
                        <input class="form-check-input" type="checkbox" checked={dtmf} onClick={handleDTMFChange} disabled={isDTMFCheckboxDisabled}/><span>Enable DTMF Voice</span>
                      </label>
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CMPDashboard;
