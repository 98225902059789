import React, { Component } from 'react'
import PricingUI from "../Components/CMPPricing";
import PanelNavigation from "../Components/CMPPanelNav";

export default class Pricing extends Component {

  render() {
    return (
      <>
        <PricingUI/>
        <PanelNavigation/>
      </>
    )
  }
}
