import React from "react";
import { Container, Navbar, Row, Col, Card, Button, Form, Alert } from "react-bootstrap";
import { useAuth } from "../Components/CMPAuthContext";
import { useState, useEffect } from "react";

const CMPCallerID = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [callerid, setCallerID] = useState("");
  const [hasRun, setHasRun] = useState(false);

  const handleCallerIDChange = (event) => {
    setCallerID(event.target.value);
  };

  const auth = useAuth();
  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    const result = await auth.setCallerIDAction({
      callerid: callerid,
    });

    if(result === true) {
      setError(false);
      setSuccess("Successfully set Caller-ID!");
    } else {
      setSuccess(false);
      setError(result);
    }

    return;
  };

  const getCallerIDEvent = async () => {
    const result = await auth.getCallerIDAction();

    if(result === false) {
      auth.logOut();
    } else {
      setCallerID(result);
    }
  };
  
  useEffect(() => {
    if(!hasRun) {
      getCallerIDEvent();
      setHasRun(true);
    }
  });

  return (
    <>
      <Navbar bg="dark" variant="dark" className="mb-3">
        <Container>
          <Navbar.Brand className="dashboard-title">
            Caller-ID
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="dashboard-navbar" />
          <Navbar.Collapse id="dashboard-navbar"></Navbar.Collapse>
        </Container>
      </Navbar>

      <Container className="my-5 ">
      <Row className='callerid-container'>
        <Col lg={6}>
        <h2>Caller-ID</h2>
          <hr />
          <Card>
            <Card.Body>
              <Card.Title>Set Caller-ID</Card.Title>
              <Form onSubmit={handleSubmitEvent}>
                {error && (
                  <Alert variant="danger">
                    {error}
                  </Alert>
                )}

                {success && (
                  <Alert variant="success">
                    {success}
                  </Alert>
                )}

                <Form.Group className="mb-3">
                  <Form.Label>Caller-ID (Leave Empty to set in Softphone)</Form.Label>
                  <Form.Control
                    type="text"
                    value={callerid}
                    placeholder="Enter Caller-ID (e.g. 49XXXXXXXXX)"
                    onChange={handleCallerIDChange}
                    id="callerid"
                  />
                </Form.Group>

                <div className="d-flex gap-3">
                  <Button
                    className="btn-dark"
                    type="submit"
                    id="calleridSubmit"
                  >
                    Set Caller-ID
                  </Button>
                </div>
              </Form>
              
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default CMPCallerID;
