import React, { Component } from "react";
import SubscriptionUI from "../Components/CMPSubscription";
import PanelNavigation from "../Components/CMPPanelNav";

export default class Subscription extends Component {

  render() {
    return (
      <>
        <SubscriptionUI />
        <PanelNavigation/>
      </>
    );
  }
}
