import React from "react";
import { Container, Navbar, Row, Col, Card, Button, Form, Alert, ListGroup, ListGroupItem } from "react-bootstrap";
import { useAuth } from "../Components/CMPAuthContext";
import { useState, useEffect } from "react";

const CMPSubscription = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [isActive, setIsActive] = useState(false);
  const [activeUntil, setActiveUntil] = useState("");

  const [canUseButton, setCanUseButton] = useState(false);

  const [hasRun, setHasRun] = useState(false);

  const auth = useAuth();
  const handleSubmitEvent = async (e) => {
    setCanUseButton(false);
    e.preventDefault();
    const result = await auth.renewSubscriptionAction();

    if(result === false) {
        auth.logOut();
    } else {
        setCanUseButton(true);
        if(result.status === "success") {
            setError(false);
            setSuccess("Successfully renewed Subscription!");

            if(result.data.active === true) {
                setIsActive(true);
            }
            setActiveUntil(result.data.expire);
        } else {
            setSuccess(false);
            setError(result.message); //Some error renewing Subscription, show it to the User so he is not lost!
        }
    }

    return;
  };

  const getSubscriptionEvent = async () => {
    const result = await auth.getSubscriptionAction();

    if(result === false) {
      auth.logOut();
    } else {
        if(result.status === "success") {
            setCanUseButton(true);
            if(result.data.active === true) {
                setIsActive(true);
            }
            setActiveUntil(result.data.expire);
        } else {
            setSuccess(false);
            setError(result.message); //Some error getting Subscription Data, show it to the User so he is not lost!
        }
    }
  };
  
  useEffect(() => {
    if(!hasRun) {
      getSubscriptionEvent();
      setHasRun(true);
    }
  });

  return (
    <>
      <Navbar bg="dark" variant="dark" className="mb-3">
        <Container>
          <Navbar.Brand className="dashboard-title">
            Subscription
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="dashboard-navbar" />
          <Navbar.Collapse id="dashboard-navbar"></Navbar.Collapse>
        </Container>
      </Navbar>

      <Container className="my-5 ">
      <Row className='subscription-container'>
        <Col lg={5}>
        <h2>Subscription</h2>
          <hr />
          <Card>
            <Card.Body>
              <Card.Title style={ isActive ? { color: 'green' } : { color: 'red' }}>STATUS: {isActive ? ("ACTIVE") : ("INACTIVE")}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">150€<span className="period">/month</span></Card.Subtitle>
              <Form onSubmit={handleSubmitEvent}>
                {error && (
                  <Alert variant="danger">
                    {error}
                  </Alert>
                )}

                {success && (
                  <Alert variant="success">
                    {success}
                  </Alert>
                )}

                <Form.Group className="mb-3">
                  <Form.Label>Active until: {activeUntil}</Form.Label>
                </Form.Group>

                <Form.Group className="mb-3">
                    <ListGroup as="ul">
                        <ListGroup.Item as="li">Single User Access</ListGroup.Item>
                        <ListGroup.Item as="li">Set your own Caller-ID!</ListGroup.Item>
                    </ListGroup>
                </Form.Group>

                <div className="d-flex gap-3">
                  <Button
                    className="btn-dark"
                    type="submit"
                    id="calleridSubmit"
                    disabled={isActive ? "true" : (canUseButton ? "" : "true")}
                  >
                    Renew Subscription
                  </Button>
                </div>
              </Form>
              
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default CMPSubscription;
