import React from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Navbar,
} from "react-bootstrap";

const CMPPricing = () => {
  //Hardcoded for now
  const pricingData = [
    {
      country: "All Rates",
      price: "€0.30 / min",
    },
  ];

  return (
    <>
      <Navbar bg="dark" variant="dark" className="mb-3">
        <Container>
          <Navbar.Brand className="dashboard-title">
            Pricing
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="dashboard-navbar" />
          <Navbar.Collapse id="dashboard-navbar"></Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="my-5">
        <h2>Pricing</h2>
        <hr />
        <Row>
          <Col>
            <ListGroup>
              {pricingData.map((item, index) => (
                <ListGroupItem key={index}>
                  <Row className="align-items-center">
                    <Col>{item.country}</Col>
                    <Col className="text-end">{item.price}</Col>
                  </Row>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CMPPricing;
