import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [linked_sip, setLinkedSIP] = useState();
  const [wallet, setWallet] = useState(null);
  const navigate = useNavigate();
  const loginAction = async (data) => {
    try {
      const response = await fetch("/api/login.php", {
        method: "POST",
        credentials:"include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        return "Backend Error status: " + response.status;
      }
      const res = await response.json();
      if (res.data) {
        if (res.status === "success") {
            setUser(res.data.user);
            setWallet(res.data.wallet);
            setLinkedSIP(res.data.sip);
            navigate("/dashboard");
            return "";
        } else {
            console.log(res.message);
            return res.message;
        }
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };

  const registerAction = async (data) => {
    try {
      const response = await fetch("/api/register.php", {
        method: "POST",
        credentials:"include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        return "Backend Error status: " + response.status;
      }
      const res = await response.json();
      if (res.data) {
        if (res.status === "success") {
            return true;
        } else {
            return res.message;
        }
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };

  const getBalanceAction = async () => {
    try {
      const response = await fetch("/api/balance.php", {
        method: "GET",
        credentials:"include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        return "Backend Error status: " + response.status;
      }
      const res = await response.json();
      if (res.data) {
        if (res.status === "success") {
          return res.data.balance;
        } else if (res.status === "unauthenticated") {
          return false;
        } else {
          return res.message;
        }
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };

  const getTransactionsAction = async () => {
    try {
      const response = await fetch("/api/transactions.php", {
        method: "GET",
        credentials:"include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        return false;
      }
      const res = await response.json();
      if (res.data) {
        if (res.status === "success") {
          return res.data.deposits;
        } else if (res.status === "unauthenticated") {
          logOut();
        } else {
          return false;
        }
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };

  const getCallerIDAction = async () => {
    try {
      const response = await fetch("/api/callerid.php", {
        method: "GET",
        credentials:"include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        return "Backend Error status: " + response.status;
      }
      const res = await response.json();
      if (res.data) {
        if (res.status === "success") {
          return res.data.callerid;
        } else if (res.status === "unauthenticated") {
          return false;
        } else {
          return res.message;
        }
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };

  const setCallerIDAction = async (data) => {
    try {
      const response = await fetch("/api/callerid.php", {
        method: "POST",
        credentials:"include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        return "Backend Error status " + response.status;
      }
      const res = await response.json();
      if (res.data) {
        if (res.status === "success") {
            //setUser(res.data.user);
            //setWallet(res.data.wallet);
            return true;
        } else if (res.status === "unauthenticated") {
          logOut();
        } else {
            return res.message;
        }
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };

  const getSubscriptionAction = async () => {
    try {
      const response = await fetch("/api/subscription.php", {
        method: "GET",
        credentials:"include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        return "Backend Error status: " + response.status;
      }
      const res = await response.json();
      if (res.data) {
        if (res.status === "unauthenticated") {
          return false;
        } else {
          return res;
        }
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };

  const renewSubscriptionAction = async () => {
    try {
      const response = await fetch("/api/subscription.php", {
        method: "POST",
        credentials:"include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        return "Backend Error status: " + response.status;
      }
      const res = await response.json();
      if (res.data) {
        if (res.status === "unauthenticated") {
          return false;
        } else {
          return res;
        }
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };

  const logOut = async () => {
    try {
      const response = await fetch("/api/logout.php", {
        method: "GET",
        credentials:"include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      if (res.data) {
        if (res.status === "success") {
          setUser(null);
          setWallet(null);
          navigate("/");
        }

        setUser(null);
        setWallet(null);
        setLinkedSIP(null);
        navigate("/");
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };

  const getDTMFAction = async () => {
    try {
      const response = await fetch("/api/dtmf.php", {
        method: "GET",
        credentials:"include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        //TODO: Error Handling
        //return "Backend Error status " + response.status;
        return false;
      }
      const res = await response.json();
      if (res.data) {
        if (res.status === "success") {
          return res.data.dtmf;
        } else if (res.status === "unauthenticated") {
          logOut();
        } else {
          //TODO: Error Handling
          //return res.message;
          return false; //Should return error
        }
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };

  const setDTMFAction = async (data) => {
    try {
      const response = await fetch("/api/dtmf.php", {
        method: "POST",
        credentials:"include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        //TODO: Error Handling
        //return "Backend Error status " + response.status;
        return !data.dtmf; //Keep the same Setting as before!
      }
      const res = await response.json();
      if (res.data) {
        if (res.status === "success") {
            return res.data.dtmf;
        } else if (res.status === "unauthenticated") {
          logOut();
        } else {
            //TODO: Error Handling
          //return res.message;
          return !data.dtmf; //Keep the same Setting as before!
          //return false; //Should return error
        }
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthContext.Provider value={{ wallet, user, linked_sip, loginAction, registerAction, getBalanceAction, getTransactionsAction, getCallerIDAction, setCallerIDAction, getSubscriptionAction, renewSubscriptionAction, getDTMFAction, setDTMFAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};