import React, { Component } from "react";
import CallerUI from "../Components/CMPCallerID";
import PanelNavigation from "../Components/CMPPanelNav";

export default class CallerID extends Component {

  render() {
    return (
      <>
        <CallerUI />
        <PanelNavigation/>
      </>
    );
  }
}
