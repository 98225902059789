import React from 'react'
import { Container, Row, Col, Card, ListGroup, ListGroupItem, Navbar } from 'react-bootstrap';
import { useAuth } from "../Components/CMPAuthContext";
import { useState, useEffect } from "react";

const CMPTopup = () => {
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [hasRun, setHasRun] = useState(false);

  const auth = useAuth();
  const getTransactionsEvent = async () => {
    const result = await auth.getTransactionsAction();

    if(result === false) {
      setError(true);
    } else {
      setData(result);
    }
  };
  
  useEffect(() => {
    if(!hasRun) {
      getTransactionsEvent();
      setHasRun(true);
    }
  });

  return (
    <>
    <Navbar bg="dark" variant="dark" className="mb-3">
          <Container>
            <Navbar.Brand className="dashboard-title">Top Up Page</Navbar.Brand>
            <Navbar.Toggle aria-controls="dashboard-navbar" />
            <Navbar.Collapse id="dashboard-navbar">
            </Navbar.Collapse>
          </Container>
        </Navbar>
    <Container className="my-5 ">
      <Row className='topup-container'>
        <Col lg={6}>
        <h2>Top Up</h2>
          <hr />
          <Card>
            <Card.Body>
              <Card.Title>BTC Wallet Address</Card.Title>
              <Card.Text className='topup-btc-address' id='topup-btc-address'>{useAuth().wallet}</Card.Text>
              <Card.Text className='topup-btc-hint' id='topup-btc-hint'>(Transactions are added to your Balance after 1 Confirmations!)</Card.Text>
              <img
                src={"https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=" + useAuth().wallet}
                alt="BTC Wallet QR Code"
                className="img-fluid topup-btc-qr"
                id="topup-btc-qr"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <h2>Last 5 Transactions</h2>
          <hr />

          {error ? (<h1>No Transaction History</h1>) : (data && (
            <ListGroup>
              {data &&
                data.map((item) => (
                  <ListGroupItem action>
                    <Row className="align-items-center topup-transaction-row" id="topup-transaction-row">
                      <Col ><a href={"https://www.blockchain.com/explorer/transactions/btc/" + item.txhash} target="_blank">View</a></Col>
                      <Col className="text-end">{item.status}</Col>
                    </Row>
                    <p className="mb-0 topup-transaction-amount" id="topup-transaction-amount">{item.amount} BTC {"(" + item.amount_in_eur + "€)"}</p>
                  </ListGroupItem>
                ))}
            </ListGroup>
          ))}
          
          
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default CMPTopup