import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from "./Components/CMPHeader";
import AuthProvider from "./Components/CMPAuthContext";

function App() {
  return (
    <div className="App min-vh-100">
      <Header />
    </div>
  );
}

export default App;
