import React, { useState } from "react";
import { Offcanvas, Nav, NavLink, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import "../Pages/styles.css";
import { useAuth } from "../Components/CMPAuthContext";

const CMPPanelNav = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const auth = useAuth();
  const handleLogout = async () => {
    const result = await auth.logOut();
    console.log(result);
  };

  return (
    <>
    
      <div style={{ display: "flex", height: "100vh", overflowY: "auto" }}>
        <div
          className="position-fixed top-0 start-0 h-100 d-flex align-items-center panel-button"
          style={{ writingMode: "vertical-rl", transform: "rotate(90deg)" }}
        >
          <button
            className="btn btn-primary btn-grey-trans h-100 panel-button-text"
            onClick={handleShow}
            style={{ writingMode: "vertical-rl", transform: "rotate(90deg)" }}
          >
            Panel Navigation
          </button>
        </div>
        
        <Offcanvas
          className="panel-nav"
          show={show}
          onHide={handleClose}
          placement="start"
        >
          <Offcanvas.Header
            className="border-bottom border-secondary bg-grey-light"
            closeButton
          >
            <Container className="d-flex align-items-start flex-column">
            <Offcanvas.Title>Panel Navigation</Offcanvas.Title>
            <span className="panel-username">{useAuth().user}</span>
            </Container>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="panel-links flex-column">

              <NavLink><Link to="/dashboard">Dashboard</Link></NavLink>
              <NavLink><Link to="/topup">Top Up</Link></NavLink>
              <NavLink><Link to="/callerid">Caller ID</Link></NavLink>
              <NavLink><Link to="/subscription">Subscription</Link></NavLink>
              <NavLink><Link to="/pricing">Pricing</Link></NavLink>
              <NavLink onClick={handleLogout}>Logout</NavLink>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      
    </>
  );
};

export default CMPPanelNav;
