import React, { Component } from "react";
import TopupUI from "../Components/CMPTopup";
import PanelNavigation from "../Components/CMPPanelNav";

export default class Topup extends Component {
  state = {
    currentPage: "/panel",
  };

  setCurrentPage = (page) => {
    this.setState({ currentPage: page });
  };
  render() {
    return (
      <>
        <TopupUI setCurrentPage={this.setCurrentPage} />
        <PanelNavigation setCurrentPage={this.setCurrentPage} />
      </>
    );
  }
}
