import React from "react";
import { useState, useEffect } from "react";
import { Container, Button, Form, Alert} from "react-bootstrap";
import { useAuth } from "../Components/CMPAuthContext";
import "./styles.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [hasRun, setHasRun] = useState(false);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const auth = useAuth();
  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    if (username !== "" && password !== "") {
      const result = await auth.loginAction({
        username: username,
        password: password,
      });
      setError(result);
      return;
    } else {
      setError("Please provide a valid input");
    }
  };

  const checkIfLoggedInEvent = async () => {
    const result = await auth.loginAction({
      username: username,
      password: password,
    });
  };

  useEffect(() => {
    if(!hasRun) {
      checkIfLoggedInEvent();
      setHasRun(true);
    }
  });

  return (
    <>
      <Container className="d-flex align-items-center justify-content-center flex-column pt-5 gap-3">
        <h1>Log in to your account</h1>
        <Form onSubmit={handleSubmitEvent}>
          {error && (
            <Alert variant="danger">
              {error}
            </Alert>
          )}

          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              value={username}
              placeholder="Enter your username"
              onChange={handleUsernameChange}
              id="username"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
              id="password"
            />
          </Form.Group>

          <div className="d-flex gap-3">
            <Button
              className="btn-trans"
              type="submit"
              //href="/dashboard"
              id="loginSubmit"
            >
              Log in
            </Button>
            <Button
              className="btn-trans"
              type="submit"
              href="/register"
              id="loginRegister"
            >
              Dont have an account?
            </Button>
          </div>
        </Form>

        <div className="d-flex gap-3" style={{margin: 20}}>
          <a href={"https://t.me/fraudsteinsupport"} target="_blank"><Button
              //className="btn-trans"
              className="btn-info"
              type="submit"
              //href="/dashboard"
              id="loginSubmit"
            >
              <i class="bi bi-telegram"></i> Send us an Message now!
            </Button></a>
          </div>
      </Container>
    </>
  );
};

export default Login;
